import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import UpdateIcon from "@material-ui/icons/Update";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import {
  formatDateByLanguage,
  secondsToTime,
} from "util";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import AttemptChip from "../../../../../components/AttemptChip";
import ProcessIconName from "../../../../../components/TableComponents/ProcessIconName";
import useStyles from "../../style";

const WorkqueueitemRow = ({
  row,
  isPendingItemsPage,
  handleFiltersChange
}) => {
  const { t } = useTranslation()
  const classes = useStyles();
  const getDateDifference = (s) => secondsToTime(s, t);
  const processDetailsObject = (parsedData) => parsedData?.filter(({ displayInItemsList }) => displayInItemsList)?.slice(0, 3);
  const getEndDate = (row) => {
    if (row?.completedDatetime) return formatDateByLanguage(row?.completedDatetime)

      if (row?.exceptionTime) return formatDateByLanguage(row?.exceptionTime)
      return "---"
}

    return (
      <>
        <CustomTableCell width={250}>
          <ProcessIconName
            imgSrc={row?.processExecution?.process?.icon}
            processName={row?.processExecution?.process?.processDescription?.processDisplayName || row?.processExecution?.process?.name}
          />
        </CustomTableCell>
        <CustomTableCell>
          <Grid container direction="column" spacing={1}>
            {row?.parsedData?.filter((item) => item?.displayInItemsList)?.length
               ? row?.parsedData && processDetailsObject(row.parsedData)?.map((parsed, i) => (
                 <Box component="span" key={`item-${i}`}>
                   <Typography variant="span" className={classes.itemDataName}>
                     {parsed?.name}
                     {" "}
                   </Typography>
                   <Typography variant="span" className={classes.itemDataValue}>
                     {": "}
                     {parsed?.value || "---"}
                   </Typography>
                 </Box>
              )) : (
                <Typography variant="span" className={classes.itemDataValue}>--- : ---</Typography>)}
          </Grid>
        </CustomTableCell>
        <CustomTableCell
          text={
            row?.loadedDatetime
              ? formatDateByLanguage(row?.loadedDatetime)
              : "---"
          }
        />
        {!isPendingItemsPage && <CustomTableCell text={getEndDate(row)} />}
        {isPendingItemsPage && (
          <CustomTableCell text={row?.deferredTime && formatDateByLanguage(row?.deferredTime)} />
        )}
        {!isPendingItemsPage && (
          <CustomTableCell text={getDateDifference(row?.worktime)} />)}
        <CustomTableCell align="right">
          {isPendingItemsPage && row?.isLocked && (
            <Tooltip title={t("items.pending.locked")}>
              <IconButton
                edge="end"
                aria-label="view"
                size="small"
                className={classes.visibilityIcon}
              >
                <VpnKeyRoundedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {isPendingItemsPage && row?.deferredTime && (
            <Tooltip
              title={t("items.pending.deferred", {
                date: formatDateByLanguage(row?.deferredTime),
              })}
            >
              <IconButton
                edge="end"
                aria-label="view"
                size="small"
                className={classes.visibilityIcon}
              >
                <UpdateIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <AttemptChip
            attempt={row.attempt}
            handleAttempClick={(itemId) => handleFiltersChange && handleFiltersChange(itemId, "originalItemId")}
            originalItemId={row?.originalItemId}
          />
        </CustomTableCell>
      </>
  );
};

export default WorkqueueitemRow;
