/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { format } from "date-fns-tz";
import { initialState } from "redux/slices/workqueueitemSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  deleteException,
  deleteExceptions,
  exportOccurrencesXLSX,
  fetchProcessesNameException,
  fetchPendingItems,
  fetchExportedFiles,
} from "redux/actions/services";
import { maxItemDetailsCount } from "util";
import { updateFilter, initFilter } from "redux/slices/pendingItemsFilter";
import { getQuery } from "@redux-requests/core";
import { useQuery } from "components/Filter";
import WorkqueueitemRow from "../components/WorkqueueitemRow";
import ExportConfirmation from "components/Filter/ExportConfirmation/ExportConfirmation";
import CustomPagination from "pages/Services/components/CustomPagination";
import CustomTableRow from "../../../../components/TableComponents/CustomTableRow";
import CustomTable from "../../../../components/TableComponents/CustomTable";
import CustomTableContainer from "../../../../components/TableComponents/CustomTableContainer";
import ConfirmMessage from "components/ConfirmMessage";
import Details from "components/DataDetail";
import DataNotFound from "components/DataNotFound";
import CircularLoader from "components/Loaders/CircularLoader";
import Filter from "../Filter";
import CompletedItemsNotFound from "assets/Process_Overview.svg";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import useStyles from "../style";
import get from "lodash/get";

const dateFormat = "yyyy/MM/dd";

function PendingItemsList(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [exceptions, setExceptions] = React.useState([]);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [messagConfirm, setMessagConfirm] = React.useState("");
  const [exceptionId, setExceptionId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMsgDetail, setOpenMsgDetail] = React.useState(false);
  const [logDetail, setLogDetail] = React.useState(null);
  const [detail, setDetail] = React.useState(null);
  const [validate, setValidate] = React.useState(false);
  const [filterStartDate, setFilterStartDate] = React.useState(null);
  const [filterEndDate, setFilterEndDate] = React.useState(null);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [exportConfirmationOpen, setExportConfirmationOpen] = React.useState(false);
  const [separateDetails, setSeparateDetails] = React.useState(false);
  const query = useQuery();
  const labels = [
    { label: "" },
    { label: t("Item Data") },
    { label: t("Creation date") },
  ];

  const pendingItemsFilter = useSelector(({ pendingItemsFilter }) => pendingItemsFilter);
  const processFilter = useSelector((state) => getQuery(state, { type: "FETCH_WORK_QUEUE_ITEM_PROCESS_ITEM_EXCEPTION" }).data);
  const pendingCount = useSelector(({ requests }) => get(requests, "queries.FETCH_WORK_QUEUE_ITEM"))?.data?.resultsCount;

  const handleChangeProcess = (values) => {
    dispatch(
      updateFilter({ ...pendingItemsFilter, pageNo: 0, process: values }),
    );
  };
  const handleChangeDates = (from, to, value) => {
    if (from && to) {
      const fromString = `${from}`;
      const toString = `${to}`;
      from = fromString.includes("/") ? from : format(from, dateFormat);
      to = toString.includes("/") ? to : format(to, dateFormat);
    }
    setFilterStartDate(from);
    setFilterEndDate(to);
    dispatch(
      updateFilter({
        ...pendingItemsFilter,
        pageNo: 0,
        completedItemsSelectedDurationValue: value,
        fromDate: from,
        toDate: to,
      }),
    );
  };

  const handleChangeFleet = (values) => {
    dispatch(
      updateFilter({
        ...pendingItemsFilter,
        pageNo: 0,
        fleet: values,
      }),
    );
  };

  const handleRequestSort = (property) => {
    dispatch(
      updateFilter({
        ...pendingItemsFilter,
        order: property,
      }),
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        ...pendingItemsFilter,
        pageNo: newPage,
      }),
    );
  };

  const handleNext = (page) => {
    dispatch(
      updateFilter({
        ...pendingItemsFilter,
        pageNo: page + 1,
      }),
    );
    }
    const handlePrevious = (page) => {
      dispatch(
        updateFilter({
          ...pendingItemsFilter,
          pageNo: page - 1,
        }),
      );
    }

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        ...pendingItemsFilter,
        pageNo: 0,
        pageSize: event.target.value,
      }),
    );
  };

  const getWorkItems = React.useCallback(
    (
      page,
      size,
      sortField,
      sortOrder,
      processes,
      fleetIds,
      filterStartDate,
      filterEndDate,
      searchText,
      tags,
      orchestratorId
    ) => {
      setIsLoading(true);
      const processID = processes ? processes.map((e) => e?.id ?? e) : [];
      const tagsID = tags ? tags.map((e) => e?.id ?? e) : [];
      props
        .fetchPendingItems(
          page,
          size,
          sortField,
          sortOrder,
          processID,
          fleetIds,
          filterStartDate,
          filterEndDate,
          searchText,
          tagsID?.join(),
          orchestratorId
        )
        .then((result) => {
          setData(result?.data?.list);
          setCount(result?.data?.resultsCount);
          setIsLoading(false);
        });
    },
    [props],
  );

  const createWqObj = () => {
    let wqObj = {};

    wqObj = {
      pageNo: pendingItemsFilter.pageNo,
      pageSize: pendingItemsFilter.pageSize,
      orderId: pendingItemsFilter.order.id,
      orderOrder: pendingItemsFilter.order.order,
      processes: pendingItemsFilter.process,
      fleets: pendingItemsFilter.fleet,
      from: pendingItemsFilter.fromDate,
      to: pendingItemsFilter.toDate,
      search: pendingItemsFilter.searchText,
      tags: pendingItemsFilter.tags,
      orchestratorId: pendingItemsFilter.orchestratorId
    };
    return wqObj;
  };

  const fetchData = () => {
    const wqObj = createWqObj();
    if (!wqObj.orchestratorId) {
      return;
    }
    getWorkItems(
      wqObj.pageNo,
      wqObj.pageSize,
      wqObj.orderId,
      wqObj.orderOrder,
      wqObj.processes,
      wqObj.fleets,
      wqObj.from,
      wqObj.to,
      wqObj.search,
      wqObj.tags,
      wqObj.orchestratorId
    );
  };
  useEffect(() => {
    fetchData();
  }, [pendingItemsFilter]);

  const deleteExceptions = () => {
    setOpenMsgConfirm(true);
    setMessagConfirm(
      `${t("Are you sure you want to delete")
      } ${
        selected.length
      } ${
        t("exceptions")
      } ?`,
    );
  };
  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setExceptionId(null);
  };
  const closeDialog = () => {
    setOpenMsgDetail(false);
  };
  const confirmDelete = () => {
    if (exceptionId) {
      props.deleteException(exceptionId).then(() => {
        setExceptionId(null);
        toast.success(t("Exception Deleted Successfully"))
        setOpenMsgConfirm(false);
        setSelected([]);
        props.fetchProcessesNameException().then(() => {
          // setProcesses(result.data);
          props.fetchExceptionsReason().then((resultE) => {
            setExceptions(resultE.data);
            getWorkItems(
              pendingItemsFilter.pageNo,
              pendingItemsFilter.pageSize,
              pendingItemsFilter.order.id,
              pendingItemsFilter.order.order,
              pendingItemsFilter.process,
              pendingItemsFilter.fleet,
              filterStartDate,
              filterEndDate,
              pendingItemsFilter.searchText,
              pendingItemsFilter.tags,
              pendingItemsFilter.orchestratorId
            );
          });
        });
      });
    } else {
      props.deleteExceptions(selected).then(() => {
        setExceptionId(null);
        toast.success(`${selected.length} ${t("exceptions successfully deleted")}`)
        setOpenMsgConfirm(false);
        setSelected([]);
        getWorkItems(
          pendingItemsFilter.pageNo,
          pendingItemsFilter.pageSize,
          pendingItemsFilter.order.order,
          pendingItemsFilter.order.order,
          pendingItemsFilter.process,
          pendingItemsFilter.fleet,
          filterStartDate,
          filterEndDate,
          pendingItemsFilter.searchText,
          pendingItemsFilter.tags,
          pendingItemsFilter.orchestratorId
        );
      });
    }
  };

  const handleOpenOccurenceDetails = (row) => {
    setOpenMsgDetail(true);
    setDetail(row?.parsedData);
    setValidate(row?.logDetail && row?.logSummary);
    setLogDetail(row?.logDetail);
  };

  const onClickRow = (row) => {
    handleOpenOccurenceDetails(row);
  };
  const handleExport = () => {
    setExportConfirmationOpen(true);
  };

  const onExportConfirm = () => {
        setExportLoading(true);
    setExportConfirmationOpen(false);
    setExportConfirmationOpen(false);
    const ids = pendingItemsFilter.process?.map(({ id }) => id);
    const fleetIds = pendingItemsFilter.fleet?.map((cId) => cId.id);
    const tagsID = pendingItemsFilter.tags?.map((e) => e?.id ?? e);
    dispatch(
      exportOccurrencesXLSX(
        ids,
        fleetIds,
        pendingItemsFilter.fromDate,
        pendingItemsFilter.toDate,
        pendingItemsFilter.searchText,
        true,
        [],
        separateDetails,
        pendingItemsFilter.order.id,
        pendingItemsFilter.order.order,
        tagsID?.join()
      ),
    ).then(() => {
      setExportLoading(false);
      dispatch(fetchExportedFiles());
      toast.success(t("export.successful.notification"));
    });
  }

  const handleChangeSearchText = (value, currFilter) => {
    dispatch(
      updateFilter({
        ...currFilter,
        pageNo: 0,
        searchText: value,
      }),
    );
  };
  const fdash = query.get("fdash");
  const handleFiltersChange = (values, target) => {
    if (fdash) dispatch(updateFilter({ ...initialState, pageNo: 0, [target]: values }));
    else {
      dispatch(
          updateFilter({ ...pendingItemsFilter, pageNo: 0, [target]: values }),
      );
    }
  };
  const handleFilterChangeBatch = (filters) =>
  {
    let obj = { ...initialState }
    filters.forEach((fi) => {
      const key = Object.keys(fi)[0]
      obj = { ...obj, [key]: fi[key] }
    })

    dispatch(updateFilter({ ...obj, pageNo: 0 }));
  }
  return (
    <Grid
      container
    >
      <Filter
          classes={classes}
          exceptions={exceptions}
          handleChangeProcess={handleChangeProcess}
          handleChangeFleet={handleChangeFleet}
          handleExport={handleExport}
          handleChangeDates={handleChangeDates}
          handleFiltersChange={handleFiltersChange}
          handleRequestSort={handleRequestSort}
          onDelete={deleteExceptions}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={data?.length}
          exportLoading={exportLoading}
          handleChangeSearchText={handleChangeSearchText}
          fetchData={fetchData}
          isPendingItemsPage
          workqueueitemFilter={pendingItemsFilter}
          initFilter={initFilter}
          handleFilterChangeBatch={handleFilterChangeBatch}
          totalCount={pendingCount}
          pageTitle="items.pending"
          filteredItemsCount={pendingCount}
        />

      <CustomTableContainer>
        <CustomTable>
          {data?.length > 0 && <CustomTableHeader labels={labels} />}
          {!isLoading && data ? (
              data.length > 0 ? (
                  data.map((row, index) => (
                    <CustomTableRow key={index} onClick={() => onClickRow(row)}>
                      <WorkqueueitemRow
                                row={row}
                                handleFiltersChange={handleFiltersChange}
                                handleOpenOccurenceDetails={handleOpenOccurenceDetails}
                                isPendingItemsPage
                                maxDetailsCount={maxItemDetailsCount(data)}
                            />
                    </CustomTableRow>
                  ))
              ) : data.length === 0 ? (
                <DataNotFound
                      message={t("no.pendingItems.message")}
                      icon={CompletedItemsNotFound}
                  />
              ) : isLoading ? (
                <CircularLoader />
              ) : null
          ) : (
            <CircularLoader />
          )}

        </CustomTable>
      </CustomTableContainer>

      <Grid container xs={12} direction="row" justify="flex-end">
        {data?.length > 0 && <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={count ?? 0}
          rowsPerPage={pendingItemsFilter.pageSize}
          page={pendingItemsFilter.pageNo}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />}
      </Grid>
      {openMsgDetail && (
        <Details
          handleClose={closeDialog}
          logDetail={logDetail}
          data={detail}
          validate={validate}
          openStart={openMsgDetail}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
        />
      )}
      {openMsgConfirm && (
        <ConfirmMessage
          message={messagConfirm}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
        />
      )}
      <ExportConfirmation
        setExportLoading={setExportLoading}
        exportConfirmationOpen={exportConfirmationOpen}
        setExportConfirmationOpen={setExportConfirmationOpen}
        setSeparateDetails={setSeparateDetails}
        onExportConfirm={onExportConfirm}
        selectedProcess={pendingItemsFilter.process.length}
        fetchedProcess={processFilter?.length} />
    </Grid>
  );
}

const mapDispatchToProps = {
  fetchPendingItems,
  fetchProcessesNameException,
  deleteException,
  deleteExceptions,
};
export default connect(null, mapDispatchToProps)(PendingItemsList);
