import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import DateFnsAdapter from "@date-io/date-fns";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  fetchFleet,
  createFleet,
  editFleet,
  fetchSubscriptions,
  fetchPaymentTerms,
  fetchFleetProcesses,
  fetchUnassignedProcesses,
  validateUnassignProcessFromFleet,
  fetchCurrentUser,
  fetchUnassignedTags, fetchFleetTags,
} from "../../../../redux/actions/services/index";
import FleetInfo from "./FleetInfo";
import FleetParameter from "./FleetParameters";
import {
  DEFAULT_CURRENCY,
  defaultDaysPerYear,
  defaultManDayHours,
  defaultRecommendedOccupancy,
  isFleetAdministrator,
} from "util";
import { useQuery } from "@redux-requests/react";
import { FETCH_FLEET_TAGS, FETCH_NOT_ASSIGNED_TAGS } from "redux/constants";
import { GROUPS_BASE_URL } from "util/helpers/route.helper";
import ConfirmMessage from "components/ConfirmMessage";
import CustomButton from "components/CustomButton";
import CircularLoader from "components/Loaders/CircularLoader";
import DialogWithTabs from "components/FormComponents/DialogWithTabs";
import ProcessAndTagSelector from "pages/Administration/ProcessAndTagSelector";

const tabs = [
  {
    id: "group-overview-tab",
    label: "fleet.formSection.fleetInfo",
  },
  {
    id: "group-settings-tab",
    label: "fleet.formSection.clientParameters",
  },
  {
    id: "group-process-tab",
    label: "fleet.formSection.processesTags",
  },
];

const FleetForm = (props) => {
  const dateFns = new DateFnsAdapter();
  const today = dateFns.date();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      companyName: "",
      siret: "",
      siren: "",
      vatNumber: "",
      phoneNumber: "",
      email: "",
      address: "",
      daysPerYear: 228,
      hoursPerManDay: 8,
      recommendedOccupancy: 17,
      clientSince: today,
      fleetSubscription: {
        effectiveStartDate: today,
      },
      instanceOwner: false,
      currency: DEFAULT_CURRENCY.code,
      beCalculationPeriod: 90
    },
  });
  const {
    handleSubmit, formState: { isDirty }, setValue, trigger, clearErrors,
  } = methods;

  const history = useHistory();

  const { t } = useTranslation();
  const { idFleet, mode = "add" } = useParams();

  const subscriptions = useSelector(
    ({ requests }) => requests.queries.FETCH_SUBSCRIPTIONS
      && requests.queries.FETCH_SUBSCRIPTIONS.data,
  );
  const paymentTerms = useSelector(
    ({ requests }) => requests.queries.FETCH_PAYMENT_TERMS
      && requests.queries.FETCH_PAYMENT_TERMS.data,
  );
  const processes = useSelector(
    ({ requests }) => requests.queries.FETCH_PROCESSES && requests.queries.FETCH_PROCESSES.data,
  );
  const fleetTags = useQuery({ type: FETCH_FLEET_TAGS })?.data;
  const tags = useQuery({ type: FETCH_NOT_ASSIGNED_TAGS })?.data;
  const userProcesses = useSelector(
    ({ requests }) => requests.queries.FETCH_FLEET_PROCESSES
      && requests.queries.FETCH_FLEET_PROCESSES.data,
  );

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [assignedTags, setAssignedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [fleetDataLoading, setFleetDataLoading] = useState(false);
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const disableFields = !(mode === "add" || mode === "edit");

  useEffect(() => {
    dispatch(fetchSubscriptions()).then((response) => {
      if (mode === "add") {
        setValue("fleetSubscription.volume", response.data[0].volume);

        setValue("fleetSubscription.rate", response.data[0].rate);
        setValue(
          "fleetSubscription.overConsumptionRate",
          response.data[0].overConsumptionRate,
        );
        setValue(
          "fleetSubscription.subscriptionPeriod",
          response.data[0].subscriptionPeriod,
        );
      }
    });
    dispatch(fetchPaymentTerms()).then((response) => {
      setValue("fleetSubscription.paymentTerm", response.data[0].frequency);
    });

    if (mode !== "add") {
      setFleetDataLoading(true);
      dispatch(fetchFleet(idFleet)).then((response) => {
        setValue("fleetId", response?.data?.id);
        setValue("companyName", response?.data?.companyName);
        setValue("phoneNumber", response?.data?.phoneNumber);
        setValue("address", response?.data?.address);
        setValue("vatNumber", response?.data?.vatNumber);
        setValue("email", response?.data?.email);
        setValue("siren", response?.data?.siren);
        setValue("siret", response?.data?.siret);
        setValue("beCalculationPeriod", response?.data?.beCalculationPeriod);
        setValue("instanceOwner", response?.data?.instanceOwner);
        setValue(
          "hoursPerManDay",
          response?.data?.hoursPerManDay || defaultManDayHours,
        );
        setValue(
          "daysPerYear",
          response?.data?.daysPerYear || defaultDaysPerYear,
        );
        setValue(
          "recommendedOccupancy",
          response?.data?.recommendedOccupancy || defaultRecommendedOccupancy,
        );

        // setValue("subscription", response.data.subscription.code);
        setValue(
          "clientSince",
          dateFns.date(response?.data?.clientSince, "yyyy/MM/DD"),
        );

        if (response?.data?.fleetSubscription) {
          setValue(
            "fleetSubscription.volume",
            response?.data?.fleetSubscription?.volume,
          );
          setValue(
            "fleetSubscription.rate",
            response?.data?.fleetSubscription?.rate,
          );
          setValue(
            "fleetSubscription.overConsumptionRate",
            response?.data?.fleetSubscription?.overConsumptionRate,
          );
          setValue(
            "fleetSubscription.paymentTerm",
            response?.data?.fleetSubscription?.paymentTerm,
          );
          setValue(
            "fleetSubscription.subscriptionPeriod",
            response?.data?.fleetSubscription?.subscriptionPeriod,
          );
          setValue(
            "fleetSubscription.effectiveStartDate",
            new Date(response?.data?.fleetSubscription?.effectiveStartDate),
          );
          setValue("currency", response?.data?.currency);
          setValue("role", response?.data?.role);
        }

        setFleetDataLoading(false);
      });
    }
  }, [idFleet, dispatch, setValue, mode]);

  useEffect(() => {
    dispatch(fetchUnassignedTags(idFleet))
    dispatch(fetchUnassignedProcesses(idFleet));
  }, [dispatch]);
  useEffect(() => {
    if (idFleet) {
      dispatch(fetchFleetProcesses(idFleet))
      dispatch(fetchFleetTags(idFleet))
    }
  }, [idFleet, dispatch]);
  useEffect(() => {
    setAvailableProcesses(processes ?? []);
  }, [processes]);
  useEffect(() => {
    if (userProcesses?.list) setAvailableProcesses((Array.isArray(processes) ? processes : [])?.filter(({ id }) => id && !(userProcesses?.list?.map(({ id }) => id)?.includes(id))) ?? []);
  }, [userProcesses]);
  useEffect(() => {
    setAvailableTags(tags?.filter(({ id }) => id && !(fleetTags?.map(({ id }) => id)?.includes(id))) ?? []);
  }, [tags, fleetTags]);
  useEffect(() => {
    if (idFleet) setAssignedProcesses(userProcesses?.list ?? []);
  }, [userProcesses, idFleet]);
  useEffect(() => {
    if (idFleet) setAssignedTags(fleetTags ?? []);
  }, [fleetTags, idFleet]);

  const redirectToList = () => history.push(GROUPS_BASE_URL);

  const handleRejectCancelForm = () => setOpenMsgCancel(false);
  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    redirectToList();
  };

  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };

  const onSubmit = (data) => {
    const dataToSubmit = {
      ...data,
      // subscription: subscriptions.find((sub) => sub.code === data.subscription),
      clientSince: data.clientSince,
      hoursPerManDay: data?.hoursPerManDay || defaultManDayHours,
      daysPerYear: data?.daysPerYear || defaultDaysPerYear,
      recommendedOccupancy:
        data?.recommendedOccupancy || defaultRecommendedOccupancy,
      fleetSubscription: {
        ...data.fleetSubscription,
        paymentTermLabel: paymentTerms.find(
          (paymentTerm) => paymentTerm.frequency === data.fleetSubscription.paymentTerm,
        ).label,
        subscriptionLabel: subscriptions.find(
          (subscription) => subscription.volume === data.fleetSubscription.volume,
        ).label,
        effectiveStartDate: data.fleetSubscription.effectiveStartDate
      },
      role: {
        id: data?.role?.id,
      },
      processes: assignedProcesses?.map((element) => ({ id: element.id })),
      tags: assignedTags?.map((element) => ({ id: element.id }))
    };
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createFleet(dataToSubmit, (res) => {
          if (res?.status === 200) {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            redirectToList();
            toast.success(t("fleet.formControl.saveSuccess"))
          }
        }),
      )
        .then((res) => {
          if (res?.response?.status === 409) {
            toast.error(t(res.response?.data?.detail))
          } else if (res.response.status !== "OK") {
            toast.error(t(res.response?.data?.message))
          }
          setIsLoading(false);
        })
        .catch(() => {});
    } else if (mode === "edit") {
      const modifyDataToSubmit = {
        ...dataToSubmit,
        fleetSubscription: {
          ...dataToSubmit.fleetSubscription,
          id: idFleet,
        },
      };

      dispatch(
        editFleet(idFleet, modifyDataToSubmit, (res) => {
          if (res.status === 200) {
            setOpenMsgConfirm(false);
            if (res.data?.id) {
              setIsLoading(false);
              redirectToList();
              toast.success(t("fleet.formControl.updateSuccess"))
              dispatch(fetchCurrentUser());
            }
          }
        }),
      )
        .then((res) => {
          const status = res?.response?.status;
          const respData = res.response?.data;
          if (status === 400 || status === 422) {
            toast.error(t(respData?.title))
          }
          else if (status === 409) {
            toast.error(t(respData?.detail))
          } else if (status !== "OK") {
            toast.error(t(respData.message))
          }
          setIsLoading(false);
        })
        .catch(() => {});
    }
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      if (activeStep === 2) {
        setOpenMsgConfirm(true);
      }
      /* to enable Sub info change to setActiveStep((prevActiveStep) => prevActiveStep + 1) */
      if (activeStep < 2) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };
  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };
  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };

  const onUnAssignProcess = (process) => dispatch(validateUnassignProcessFromFleet(process.id)).then((res) => {
      if (res?.data !== 0) {
        toast.error(t("fleet.formControl.unassignProcessError", { users: res.data }))
        return false;
      }
      return true;
    },);

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    else setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = async (_, newValue) => {
    const result = await trigger();
    if (result) {
      setActiveStep(newValue);
    }
  };

  const tabsContents = [
    {
      content: (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fleetDataLoading ? (
              <CircularLoader height="100%" />
            ) : (
              <FleetInfo
                disableFields={disableFields}
                clearErrors={clearErrors}
                today={today}
              />
            )}
          </form>
        </FormProvider>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("Cancel")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FleetParameter
              disableFields={disableFields}
              clearErrors={clearErrors}
            />
          </form>
        </FormProvider>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ProcessAndTagSelector
                availableProcesses={availableProcesses}
                selectedProcesses={assignedProcesses}
                isDisabled={disableFields || (mode === "edit" && !currentUser?.fleet.instanceOwner)}
                setAvailableProcesses={setAvailableProcesses}
                setSelectedProcesses={setAssignedProcesses}
                availableTags={availableTags}
                selectedTags={assignedTags}
                setAvailableTags={setAvailableTags}
                setSelectedTags={setAssignedTags}
                onUnAssignProcess={onUnAssignProcess}
            />
          </form>
        </FormProvider>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {idFleet ? t("fleet.button.update") : t("fleet.button.save")}
          </CustomButton>
        </>
      ),
    },
  ];

  return (
    <>
      <DialogWithTabs
        open={props?.open}
        entityBaseUrl={GROUPS_BASE_URL}
        moduleName="groups"
        tabs={tabs}
        disableFields={disableFields}
        editCondition={isFleetAdministrator(currentUser) && ((Number.parseInt(idFleet) === currentUser?.fleet?.id) || currentUser?.fleet.instanceOwner)}
        tabsContents={tabsContents}
        idItem={idFleet}
        handleChange={handleChange}
        activeStep={activeStep}
        width={1000}
        height={disableFields ? 437 : 520}
      />
      {openMsgConfirm && (
        <ConfirmMessage
          message={
            idFleet ? t("fleett.update.confirmMsg") : t("fleet.save.confirmMsg")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonCancel={t("fleet.button.cancel")}
          buttonConfirm={
            idFleet ? t("fleet.button.update") : t("fleet.button.save")
          }
          isLoading={isLoading}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("fleet.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonConfirm={t("fleet.button.discard")}
          buttonCancel={t("fleet.button.cancel")}
          isLoading={false}
        />
      )}
    </>
  );
};
export default FleetForm;
